// 引入api
import { fun_GetAction } from "@/api";
// 默认导出
export default {
  // 数据存储对象
  data: () => {
    return {
      // 弹窗状态
      dialogVisible: false,
      // 弹窗确定按钮loading状态
      loading: false,
      // 表格数据
      tableData: [],
      // 分页
      reqPageData: {
        current: 1, // 页码
        size: 10 // 每页条数
      },
      total: 0, // 总条数
      // 表格基础数据
      tableColumns: [
        {
          label: "更新所需要的文件名称",
          prop: "fileName"
        },
        {
          label: "文件地址",
          prop: "filePath"
        }
      ]
    };
  },

  // 自定义方法
  methods: {
    /**
     * 获取详情
     * @author 滕威
     */
    async fun_GetDetails(id) {
      try {
        // 打开loading
        this.loading = true;
        // 请求，获取结果集
        let res = await fun_GetAction("/clientFileInfo/detail?clientId=" + id);
        // 解构res.data
        const { result, data, msg } = res.data;
        // 判断请求是否成功
        if (result) {
          // 关闭loading
          this.loading = false;
          // 解构data
          const { total, records } = data;
          // 回显表单
          this.tableData = records;
          // 获取分页总条数
          this.total = total;
        } else {
          // 关闭loading
          this.loading = false;
          // 请求失败，提示错误信息
          this.$message.error(msg);
        }
      } catch {
        // 关闭loading
        this.loading = false;
      }
    },
    /**
     * 改变每页条数执行的函数
     * @params e 每页条数
     * @author 滕威
     */
    fun_LimitChange(e) {
      // 获取每页显示条数
      this.reqPageData.size = e;
      // 获取一览表格数据
      this.fun_GetDetails();
    },
    /**
     * 改变页码执行的函数
     * @params e 页码数
     * @author 滕威
     */
    fun_PageChange(e) {
      // 获取当前页码
      this.reqPageData.current = e;
      // 获取一览表格数据
      this.fun_GetDetails();
    }
  }
};
