// 引入抽屉新增组件
import AutoUpdateDrawer from "./components/autoUpdateDrawer/index.vue";
// 弹窗详情组件
import AutoUpdateDialog from "./components/autoUpdateDialog/index.vue";
// 引入api
import { fun_PostAction } from "@/api";
// 引入转译方法
import { fun_GetKeys } from "@/utils/validate";
// 默认导出
export default {
  // 数据存储对象
  data: () => {
    return {
      // 列表loading初始状态
      loading: false,
      // 查询表单字段
      form: {
        name: null, // 用户名称
        realName: null, // 真实姓名
        roleId: null // 角色
      },
      // 查询表单
      formSearchList: [
        {
          type: "input",
          label: "应用名称：",
          value: "clientName",
          placeholder: "请输入"
        },
        {
          type: "input",
          label: "版本号：",
          value: "versionNumber",
          placeholder: "请输入"
        }
      ],
      // 表头按钮组
      btnList: [
        {
          name: "添加",
          type: "success",
          icon: "document-add"
        },
        {
          name: "删除",
          type: "danger",
          icon: "delete"
        },
        // {
        //   name: "文件生成",
        //   type: "primary",
        //   icon: "files"
        // }
      ],
      // 表格基础数据
      tableColumns: [
        {
          label: "应用名称",
          prop: "clientName",
        },
        {
          label: "版本号",
          prop: "versionNumber"
        },
        {
          label: "更新文件地址",
          prop: "filePath"
        },
        // {
        //   label: "更新文件MD5值",
        //   prop: "fileMd5"
        // },
        {
          label: "文件量度",
          prop: "number"
        },
        {
          label: "备注",
          prop: "remarks"
        }
      ],
      // 表格数据
      tableData: [],
      // 分页
      reqPageData: {
        current: 1, // 页码
        size: 10 // 每页条数
      },
      total: 0, // 总条数
      // 勾选的数据
      multipleSelection: []
    };
  },

  // 注册组件
  components: {
    // 抽屉新增组件
    AutoUpdateDrawer,
    // 弹窗详情组件
    AutoUpdateDialog
  },

  // 模板编译完成
  mounted() {
    // 获取一览表格数据
    this.fun_GetList();
  },

  // 自定义方法
  methods: {
    /**
     * 获取一览表格数据
     * @author 滕威
     */
    async fun_GetList() {
      // 打开loading
      this.loading = true;
      // 请求，获取结果集
      let res = await fun_PostAction("/clientFileInfo/list", {
        ...this.form,
        ...this.reqPageData
      });
      // 解构res.data
      const { result, data, msg } = res.data;
      // 判断请求是否成功
      if (result) {
        // 解构data
        const { total, records } = data;
        // 回显table
        this.tableData = records;
        this.tableData.forEach(e => {
          e.number = e.number === '0' ? '增量':'全量';
        })
        // 获取分页总条数
        this.total = total;
      } else {
        // 提示请求失败消息
        this.$message.error(msg);
      }
      // 关闭loading
      this.loading = false;
    },
    /**
     * 点击删除按钮
     * @author 滕威
     */
    fun_Del() {
      // 判断是否勾选
      if (this.multipleSelection && this.multipleSelection.length > 0) {
        // 弹出询问confirm
        this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          // 获取keys
          const keys = fun_GetKeys(this.multipleSelection);
          // 发起请求，获取结果集
          fun_PostAction("/clientFileInfo/delete?keys=" + keys).then(res => {
            // 解构res.data
            const { result, msg } = res.data;
            // 判断请求是否成功
            if (result) {
              // 查询列表
              this.fun_GetList();
              // 提示删除成功
              this.$message.success(msg);
            } else {
              // 请求失败，提示信息
              this.$message.error(msg);
            }
          });
        });
      } else {
        // 未勾选，则提示
        this.$alert("请选择一条数据！", "warning");
      }
    },
    /**
     * 查询
     * @author 滕威
     */
    fun_Search() {
      // 获取一览表格数据
      this.fun_GetList();
    },
    /**
     * 改变每页条数执行的函数
     * @params e 每页条数
     * @author 滕威
     */
    fun_LimitChange(e) {
      // 获取每页显示条数
      this.reqPageData.size = e;
      // 获取一览表格数据
      this.fun_GetList();
    },
    /**
     * 改变页码执行的函数
     * @params e 页码数
     * @author 滕威
     */
    fun_PageChange(e) {
      // 获取当前页码
      this.reqPageData.current = e;
      // 获取一览表格数据
      this.fun_GetList();
    },
    /**
     * 表头按钮事件
     * @params e 按钮name
     * @author 滕威
     */
    fun_click(e) {
      switch (e) {
        case "添加":
          // 打开抽屉
          this.$refs.autoUpdateDrawer.drawerStatus = true;
          break;
        case "文件生成":
          // 文件生成
          this.fun_FileGeneration();
          break;
        default:
          // 调用删除方法
          this.fun_Del();
      }
    },
    /**
     * 勾选事件
     * @params e 选中的数据
     * @author 滕威
     */
    fun_HandleSelectionChange(e) {
      // 将勾选的value值赋给multipleSelection数组
      this.multipleSelection = e;
    },
    /**
     * 点击名称进入详情
     * @params v 子传父表格行数据
     * @author 滕威
     */
    fun_LookDetails(v) {
      // 获取id
      const id = v.val.id;
      // 打开弹窗
      this.$refs.autoUpdateDialog.dialogVisible = true;
      // 执行弹窗详情
      this.$refs.autoUpdateDialog.fun_GetDetails(id);
    },
    /**
     * 点击文件生成
     * @author 滕威
     */
    async fun_FileGeneration() {
      // 请求
      let res = await fun_PostAction("/clientFileInfo/getGenerateUpdateFile");
      // 解构res.data
      const { result, msg } = res.data;
      // 判断请求是否成功
      if (result) {
        this.fun_GetList();
        // 成功提示
        this.$message.success(msg);
      } else {
        // 失败提示
        this.$message.error(msg);
      }
    }
  }
};
